'use strict';

/* eslint-disable require-jsdoc */

$(document).ready(function () {
    var overlappedHeader = document.getElementsByClassName('header-container--overlapped-js')[0];

    function handleScroll() {
        if (window.scrollY > 100) {
            overlappedHeader.classList.remove('header-container--transition');
        } else if (!overlappedHeader.classList.contains('header-container--transition')) {
            overlappedHeader.classList.add('header-container--transition');
        }
    }

    if (overlappedHeader) {
        handleScroll();
        window.addEventListener('scroll', function () {
            handleScroll();
        });
    }
});
